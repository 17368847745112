html {
  margin: 0 auto;
  max-width: 850px;
  -webkit-text-size-adjust: 100%;
  /* Prevent font scaling in landscape while allowing user zoom */
}

body {
  max-width: 850px;
  width: 100%;
}

a,
.as-link {
  color: var(--ion-color-primary, initial);
  text-decoration: underline;
  cursor: pointer;
}

a.no-style,
.as-link.no-style {
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-position: inside;
  padding-left: 1px;
}

:root {
  --vulcan-emphasized-weight: 700;
}

.emphasize {
  font-weight: var(--vulcan-emphasized-weight);
}

.larger {
  font-size: 1.1em
}

.smaller {
  font-size: 0.9em
}

.vulcan-text-color-primary {
  color: var(--ion-color-primary);
}

.header-collapse-condense-inactive .header-background {
  backdrop-filter: none;
}

.brand-colors {
  --ion-color-primary: var(--vulcan-brand-color-primary);
  --ion-color-primary-rgb: var(--vulcan-brand-color-primary-rgb);
  --ion-color-primary-contrast: var(--vulcan-brand-color-primary-contrast);
  --ion-color-primary-contrast-rgb: var(--vulcan-brand-color-primary-contrast-rgb);
  --ion-color-primary-shade: var(--vulcan-brand-color-primary-shade);
  --ion-color-primary-tint: var(--vulcan-brand-color-primary-tint);
}


.picker-wrapper.sc-ion-picker-ios {
  --min-height: 285px;
}
