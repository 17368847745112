ion-card {
  box-shadow: none;
  --ion-item-background: var(--ion-card-background);
}

ion-label {
  font-weight: 600;
}

.action-sheet-button.action-sheet-default {
  font-weight: bold;
}

#root ion-modal {
  --max-height: 100%;
  --min-height: 100%;
  --height: 100%;
  --max-width: 100%;
  --min-width: 100%;
  --width: 100%;
  --border-radius: 0;
}

#root .toast-position-bottom {
  transform: translateY(-50px);
}
